



































import { BackButton,FloatingFooter, ForgotPasswordView, InputBox, LoadingButton, NavigationBar } from "@restofrit/components"
import { NetworkManager, Session } from "@restofrit/networking";
import { PasswordGrantStruct, Token } from "@restofrit/structures";
import { ErrorBox, GeneralErrorsView } from "@simonbackx/simple-error-forms";
import { ComponentWithProperties, HistoryManager, NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins } from "vue-property-decorator";

@Component({
    components: {
        InputBox,
        GeneralErrorsView,
        LoadingButton,
        FloatingFooter,
        NavigationBar,
        BackButton
    },
})
export default class LoginView extends Mixins(NavigationMixin) {
    errorBox: ErrorBox | null = null
    email = ""
    password = ""
    loading = false

    mounted() {
        HistoryManager.setUrl("/login");
    }

    async submit() {
        if (this.loading) {
            return
        }
        this.loading = true
        this.errorBox = null

        try {
            const response = await NetworkManager.server.request({
                method: "POST",
                path: "/auth/token",
                body: new PasswordGrantStruct({
                    username: this.email,
                    password: this.password
                }),
                decoder: Token
            })
            await Session.shared.setToken(response.data)
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.loading = false
    }

    forgot() {
        this.present(new ComponentWithProperties(ForgotPasswordView).setDisplayStyle("sheet"))
    }
    
}
