






















import { FloatingFooter,InputBox, LoadingButton, ResetForgotPasswordView } from "@restofrit/components"
import { ErrorBox, GeneralErrorsView } from "@simonbackx/simple-error-forms";
import { ComponentWithProperties, HistoryManager, NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins } from "vue-property-decorator";

import LoginView from "./LoginView.vue";
import PrivacyView from "./PrivacyView.vue";
import SignUpView from "./SignUpView.vue";

@Component({
    components: {
        InputBox,
        GeneralErrorsView,
        LoadingButton,
        FloatingFooter
    },
})
export default class WelcomeView extends Mixins(NavigationMixin) {
    errorBox: ErrorBox | null = null
    loading = false

    createAccount() {
        this.show(new ComponentWithProperties(SignUpView, {}))
    }

    login() {
        this.show(new ComponentWithProperties(LoginView, {}))
    }

    mounted() {
        console.log("Welcome view mounted")
        const params = new URL(window.location.href).searchParams;
        const parts = window.location.pathname.substring(1).split("/");
        HistoryManager.setUrl("/");
        if (parts.length == 1 && parts[0] == "reset-password") {
            console.log("Detected forgot password url")
            this.present(new ComponentWithProperties(ResetForgotPasswordView, {
                token: params.get("token")
            }).setDisplayStyle("popup").setAnimated(false))
        }

        if (parts.length == 1 && parts[0] == "privacy") {
            console.log("Detected privacy url")
            this.present(new ComponentWithProperties(PrivacyView).setDisplayStyle("popup").setAnimated(false))
        }
    }
    
}
