






























import { FloatingFooter, InputBox, List, ListItem,LoadingButton, NavigationBar, Radio } from "@restofrit/components"
import { NetworkManager } from "@restofrit/networking";
import { CreateCustomer, Wholesaler } from "@restofrit/structures";
import { ArrayDecoder, Decoder } from "@simonbackx/simple-encoding";
import { ErrorBox, GeneralErrorsView } from "@simonbackx/simple-error-forms";
import { ComponentWithProperties, NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins, Prop } from "vue-property-decorator";

import CompanySettingsView from "./CompanySettingsView.vue";

@Component({
    components: {
        InputBox,
        GeneralErrorsView,
        LoadingButton,
        FloatingFooter,
        NavigationBar,
        List, 
        ListItem,
        Radio
    },
})
export default class ChooseWholesalerView extends Mixins(NavigationMixin) {
    errorBox: ErrorBox | null = null
    loading = false
    wholesalers: Wholesaler[] | null = null
    selectedWholesaler: Wholesaler | null = null
    cachedCustomer: CreateCustomer | null = null

    @Prop({ required: true })
    callback: () => void

    mounted() {
        this.loadWholesalers().catch(e => {
            console.error(e)
        })
    }

    async loadWholesalers() {
        try {
            const response = await NetworkManager.server.request({
                method: "GET",
                path: "/customers/wholesalers",
                decoder: new ArrayDecoder(Wholesaler as Decoder<Wholesaler>)
            })
            this.wholesalers = response.data
        } catch (e) {
            this.errorBox = new ErrorBox(e)
            this.wholesalers = []
        }
    }

    next() {
        if (!this.selectedWholesaler) {
            return;
        }

        // Cache customer, so you don't have to reenter all information when going back
        const customer = this.cachedCustomer ?? CreateCustomer.create({
            wholesaler: this.selectedWholesaler
        })
        customer.wholesaler = this.selectedWholesaler
        this.cachedCustomer = customer
        this.show(new ComponentWithProperties(CompanySettingsView, { customer, callback: this.callback }))
    }
    
}
