













import { InputBox, WholesalerLogo } from "@restofrit/components"
import { Customer, CustomerStatus } from "@restofrit/structures";
import { ErrorBox } from "@simonbackx/simple-error-forms";
import { ComponentWithProperties, NavigationController, NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";

import PromotionsView from "../promotions/PromotionsView.vue";
import TabBarController from "../promotions/TabBarController.vue";
import { TabBarItem } from "../promotions/TabBarItem";
import SettingsView from "../settings/SettingsView.vue";

@Component({
    components: {
        InputBox,
        WholesalerLogo
    },
})
export default class WaitingForVerificationView extends Mixins(NavigationMixin) {
    @Prop({ required: true })
    customer: Customer
    
    errorBox: ErrorBox | null = null
    loading = false

    reloading = false

    @Watch('customer.settings.status')
    onChangeStatus() {
        console.log("Customer status changed")
        if (this.customer.settings.status == CustomerStatus.Accepted) {
            this.viewPromotions()
        }
    }

    viewPromotions() {
        this.show({
            components: [
                new ComponentWithProperties(TabBarController, {
                    items: [
                        new TabBarItem("promotions.title", "promotions", new ComponentWithProperties(NavigationController, { 
                            root: new ComponentWithProperties(PromotionsView, {
                                customer: this.customer
                            })
                        })),
                        new TabBarItem("settings.title", "settings", new ComponentWithProperties(NavigationController, { 
                            root: new ComponentWithProperties(SettingsView, {
                                customer: this.customer
                            })
                        }))
                    ]
                })
            ],
            animated: true,
            replace: 1,
            force: true
        })
    }
    
}
