

























































import { BackButton,FloatingFooter, InputBox, List, ListItem,LoadingButton, NavigationBar, NumberInput,PromotionBox } from "@restofrit/components"
import { Session } from "@restofrit/networking";
import { Customer, Promotion, PromotionCustomer } from "@restofrit/structures";
import { Formatter } from "@restofrit/utilities";
import { ErrorBox, GeneralErrorsView, Validator } from "@simonbackx/simple-error-forms";
import { ComponentWithProperties, NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins, Prop } from "vue-property-decorator";

import ConfirmPromotionView from "./ConfirmPromotionView.vue";

@Component({
    components: {
        InputBox,
        GeneralErrorsView,
        NumberInput,
        LoadingButton,
        FloatingFooter,
        NavigationBar,
        List, 
        ListItem,
        PromotionBox,
        BackButton
    },
})
export default class PromotionView extends Mixins(NavigationMixin) {
    loading = false

    @Prop({ required: true })
    promotion!: PromotionCustomer

    @Prop({ required: true })
    customer: Customer

    validator = new Validator()
    errorBox: ErrorBox | null = null
    amount = this.minimum

    mounted() {
        // Register view
        Session.shared.authenticatedServer.request({
            method: "POST",
            path: "/customers/"+this.customer.id+"/promotions/"+this.promotion.id+"/viewed",
        }).catch(console.error)
    }

    formatDate(d: Date) {
        return Formatter.date(d)
    }

    next() {
        this.show(new ComponentWithProperties(ConfirmPromotionView, {
            promotion: this.promotion,
            customer: this.customer,
            amount: this.amount
        }))
    }

    get alreadyOrderedCount() {
        return this.promotion.orders.reduce((count, order) => count + order.settings.amount, 0)
    }

    get remainingMaximum() {
        if (this.promotion.settings.maximumAmount === null) {
            return null
        }
        return this.promotion.settings.maximumAmount - this.alreadyOrderedCount
    }

    get minimum() {
        return Math.max(1, (this.promotion.settings.minimumAmount || 1) - this.alreadyOrderedCount)
    }

    get disableOrder() {
        if (this.promotion.settings.maximumAmount === null) {
            return false
        }
        return this.promotion.settings.maximumAmount - this.alreadyOrderedCount < 1
    }

    blur() {
        (document.activeElement as any)?.blur()
    }
}
