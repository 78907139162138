




































import { BackButton,FloatingFooter, InputBox, LoadingButton, NavigationBar } from "@restofrit/components"
import { CreateCustomer } from "@restofrit/structures";
import { ErrorBox, GeneralErrorsView } from "@simonbackx/simple-error-forms";
import { SimpleError, SimpleErrors } from "@simonbackx/simple-errors";
import { ComponentWithProperties, NavigationMixin } from "@simonbackx/vue-app-navigation";
import { checkVAT, countries } from 'jsvat';
import { Component, Mixins, Prop } from "vue-property-decorator";

import CategorySelectionView from "./CategorySelectionView.vue";

@Component({
    components: {
        InputBox,
        GeneralErrorsView,
        LoadingButton,
        FloatingFooter,
        NavigationBar,
        BackButton
    },
})
export default class CompanySettingsView extends Mixins(NavigationMixin) {
    @Prop({ required: true })
    customer: CreateCustomer

    @Prop({ required: true })
    callback: () => void

    errorBox: ErrorBox | null = null
    loading = false

    get businessName() {
        return this.customer.settings.businessName
    }

    set businessName(businessName: string) {
        this.customer.settings.businessName = businessName
    }

    get city() {
        return this.customer.settings.city
    }

    set city(city: string) {
        this.customer.settings.city = city
    }

    get VATNumber() {
        return this.customer.settings.VATNumber
    }

    set VATNumber(name: string) {
        this.customer.settings.VATNumber = name
    }

    next() {
        try {
            this.errorBox = null

            this.businessName = this.businessName.trim()
            this.VATNumber = this.VATNumber.trim()

            const errors = new SimpleErrors()
            if (this.businessName.length == 0) {
                errors.addError(new SimpleError({
                    code: "invalid_field",
                    message: "businessName is too short",
                    human: this.$t('errors.emptyBusinessName').toString(),
                    field: "businessName"
                }))
            }

            if (this.city.length == 0) {
                errors.addError(new SimpleError({
                    code: "invalid_field",
                    message: "city is too short",
                    human: this.$t('errors.emptyCity').toString(),
                    field: "city"
                }))
            }

            if (this.VATNumber.length > 0 && !checkVAT(this.VATNumber, countries).isValid) {
                errors.addError(new SimpleError({
                    code: "invalid_field",
                    message: "VATNumber is too short",
                    human: this.$t('errors.invalidVATNumber').toString(),
                    field: "VATNumber"
                }))
            }

            errors.throwIfNotEmpty()
            this.show(new ComponentWithProperties(CategorySelectionView, { customer: this.customer, callback: this.callback }))
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
   
    }
    
}
