
















import { FramedComponent, NavigationController } from "@simonbackx/vue-app-navigation";
import { NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins,Prop } from "vue-property-decorator";

import { TabBarItem } from "./TabBarItem";

@Component({
    components: {
        FramedComponent
    }
})
export default class TabBarController extends Mixins(NavigationMixin) {
    @Prop({ required: true })
    items!: TabBarItem[]

    selectedItem: null | TabBarItem = null

    get activeItem() {
        return this.selectedItem ?? this.items[0]
    }

    get root() {
        return this.activeItem.component
    }

    selectItem(item: TabBarItem) {
        const scrollElement = this.getScrollElement()
        if (this.activeItem === item) {
            if (scrollElement.scrollTop > 0) {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            } else {
                const navigationController = this.activeItem.component.componentInstance()
                console.log("try pop", navigationController)
                if (navigationController && navigationController instanceof NavigationController) {
                    if (navigationController.components.length > 1) {
                        // try to pop
                        navigationController.pop().catch(console.error)
                    }
                }
            }
            
            return
        }

        this.activeItem.savedScrollPosition = scrollElement.scrollTop;

        // Keep the component alive while it is removed from the DOM
        this.activeItem.component.keepAlive = true;

        this.selectedItem = item

        requestAnimationFrame(() => {
            // Need to wait for a rerender, because DOM is not updated yet
            if (this.activeItem.savedScrollPosition) {
                console.log("restoring scroll position", this.activeItem.savedScrollPosition);
                scrollElement.scrollTop = this.activeItem.savedScrollPosition
            } else {
                scrollElement.scrollTop = 0;
            }
        })
    }

    getScrollElement(element: HTMLElement | null = null): HTMLElement {
        if (!element) {
            element = this.$el as HTMLElement;
        }

        const style = window.getComputedStyle(element);
        if (style.overflowY == "scroll" || style.overflow == "scroll" || style.overflow == "auto" || style.overflowY == "auto") {
            return element;
        } else {
            if (!element.parentElement) {
                return document.documentElement;
            }
            return this.getScrollElement(element.parentElement);
        }
    }

    destroyed() {
        // Prevent memory issues by removing all references and destroying kept alive components
        for (const item of this.items) {
            // Destroy them one by one
            if (item.component.isKeptAlive) {
                item.component.destroy();
            }
        }
    }
}
