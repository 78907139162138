




































import { BackButton,FloatingFooter, InputBox, LoadingButton, NavigationBar } from "@restofrit/components"
import { NetworkManager, Session } from "@restofrit/networking";
import { Token } from "@restofrit/structures";
import { ErrorBox, GeneralErrorsView } from "@simonbackx/simple-error-forms";
import { SimpleError } from "@simonbackx/simple-errors";
import { HistoryManager, NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins } from "vue-property-decorator";

@Component({
    components: {
        InputBox,
        GeneralErrorsView,
        LoadingButton,
        FloatingFooter,
        NavigationBar,
        BackButton
    },
})
export default class SignUpView extends Mixins(NavigationMixin) {
    errorBox: ErrorBox | null = null
    email = ""
    password = ""
    repeatPassword = ""
    loading = false

    mounted() {
        HistoryManager.setUrl("/register");
    }

    async submit() {
        if (this.loading) {
            return
        }
        this.loading = true
        this.errorBox = null

        try {
            if (this.password != this.repeatPassword) {
                throw new SimpleError({
                    code: "invalid_field",
                    message: this.$t('errors.repeatPassword').toString(),
                    field: "repeatPassword"
                })
            }
            if (this.password.length < 8) {
                throw new SimpleError({
                    code: "invalid_field",
                    message: this.$t('errors.passwordTooShort').toString(),
                    field: "password"
                })
            }
            const response = await NetworkManager.server.request({
                method: "POST",
                path: "/auth/create-user",
                body: {
                    email: this.email,
                    password: this.password
                },
                decoder: Token
            })
            await Session.shared.setToken(response.data)
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.loading = false
    }
    
}
