





















import { FloatingFooter, NavigationBar } from "@restofrit/components"
import { NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins } from "vue-property-decorator";

@Component({
    components: {
        FloatingFooter,
        NavigationBar,
    },
})
export default class ConfirmationView extends Mixins(NavigationMixin) {
    close() {
        this.dismiss({ force: true })
    }
}
