
































import { BackButton, List, ListItem, NavigationBar } from "@restofrit/components";
import { Session } from "@restofrit/networking";
import { Customer } from "@restofrit/structures";
import { ComponentWithProperties, NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins, Prop } from "vue-property-decorator";

import LanguageSelectionView from "./LanguageSelectionView.vue";
import NotificationSettingsView from "./NotificationSettingsView.vue";

@Component({
    components: {
        NavigationBar,
        List, 
        ListItem,
        BackButton
    },
})
export default class SettingsView extends Mixins(NavigationMixin) {
    @Prop({ required: true })
    customer: Customer
    
    openLanguageSelector() {
        this.show(new ComponentWithProperties(LanguageSelectionView, { customer: this.customer }))
    }

    openNotificationSettings() {
        this.show(new ComponentWithProperties(NotificationSettingsView, { customer: this.customer }))
    }

    logout() {
        if (!confirm(this.$t('settings.logout.confirmMessage').toString())) {
            return;
        }
        Session.shared.logout()
    }
    
}
