






















import { BackButton, List, ListItem, NavigationBar, Radio } from "@restofrit/components";
import { switchLanguage } from "@restofrit/i18n";
import { Session } from "@restofrit/networking";
import { Customer, CustomerSettings, Language, LanguageHelper } from "@restofrit/structures";
import { Decoder } from "@simonbackx/simple-encoding";
import { NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins, Prop } from "vue-property-decorator";

@Component({
    components: {
        NavigationBar,
        List, 
        ListItem,
        Radio,
        BackButton
    },
})
export default class LanguageSelectionView extends Mixins(NavigationMixin) {
    @Prop({ required: true })
    customer: Customer
    
    languages: Language[] = Object.values(Language)

    initialLanguage = this.selectedLanguage

    get selectedLanguage(): Language {
        return this.$i18n.locale.substr(0, 2) as Language
    }

    set selectedLanguage(locale: Language) {
        switchLanguage(locale).catch(console.error);
    }

    getName(language: Language) {
        return LanguageHelper.getName(language)
    }

    beforeDestroy() {
        // Save langauge to server
        if (this.initialLanguage !== this.selectedLanguage) {
            // todo

            Session.shared.authenticatedServer.request({
                method: "PATCH",
                path: "/customers/"+this.customer.id,
                body: CustomerSettings.patch({
                    language: this.selectedLanguage
                }),
                decoder: Customer as Decoder<Customer>
            }).then(request => {
                const customer = request.data
                this.customer.set(customer)
            }).catch(() => {
                console.error("Failed to save language")
            })
        }
    }
}
