

















import { App as CapApp } from '@capacitor/app';
import { Capacitor, PluginListenerHandle } from '@capacitor/core';
import { PushNotifications } from "@capacitor/push-notifications";
import { FloatingFooter, InputBox, PromotionBox, Spinner, WholesalerLogo, WholesalerTheme } from "@restofrit/components"
import { Session } from "@restofrit/networking";
import { PromotionCustomer } from '@restofrit/structures';
import { Customer, CustomerStatus, Promotion } from "@restofrit/structures";
import { ArrayDecoder, Decoder } from "@simonbackx/simple-encoding";
import { GeneralErrorsView } from "@simonbackx/simple-error-forms";
import { ComponentWithProperties, HistoryManager, NavigationController, NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";

import WaitingForVerificationView from "../welcome/WaitingForVerificationView.vue";
import PromotionView from "./PromotionView.vue";

@Component({
    components: {
        InputBox,
        GeneralErrorsView,
        FloatingFooter,
        Spinner,
        PromotionBox,
        WholesalerLogo
    },
})
export default class PromotionsView extends Mixins(NavigationMixin) {
    @Prop({ required: true })
    customer: Customer
    
    loading = false

    promotions: PromotionCustomer[] = []

    delayOpeningId: string | null = null
    listener: PluginListenerHandle | null = null

    mounted() {
        HistoryManager.setUrl("/");
        WholesalerTheme.loadColors(this.customer.wholesaler, this.modalNavigationController!.$el as HTMLElement)

        if (Capacitor.getPlatform() === 'ios' || Capacitor.getPlatform() === 'android') {
            PushNotifications.addListener("pushNotificationActionPerformed", (notification: any) => {
                console.log("Tapped push notification")
                console.log(notification)

                // We temporarily use extra AND data because of a bug in capacitor plugin
                // Reference: https://github.com/ionic-team/capacitor-plugins/pull/349
                const data = notification.notification.data ?? notification.notification.extra
                if (data && data.promotionId && typeof data.promotionId === "string") {
                    // Open the promotion
                    this.delayOpeningId = data.promotionId
                    console.log("Set delay opening id "+this.delayOpeningId)

                    if (!this.loading) {
                        this.load().catch(e => console.error(e))
                    }
                }
            }).catch(console.error)
        }

        // Add live reload to this customer
        CapApp.addListener('appStateChange', ({ isActive }) => {
            console.log('App state changed. Is active?', isActive);
            if (isActive) {
                this.load().catch(e => {
                    console.error(e)
                })
            }
        }).then(l => {
            this.listener = l
        }).catch(console.error);

        


        this.load().catch(e => {
            console.error(e)
        })
    }

    beforeDestroy() {
        if (this.listener) {
            console.log("destroy listener")
            this.listener.remove()
            this.listener = null
        }
    }

    @Watch('customer.settings.status')
    onChangeStatus() {
        console.log("Customer status changed")
        if (this.customer.settings.status != CustomerStatus.Accepted) {
            this.onBlocked()
        }
    }

    openById(promotionId: string) {
        const promotion = this.promotions.find(p => p.id === promotionId)
        if (promotion) {
            console.log("Opening promotion by id "+promotionId)
            this.openPromotion(promotion)
        } else {
            console.error("Didn't find promotion with id "+promotionId)
        }
    }

    onBlocked() {
        this.present({ // Present -> we'll replace the main view in the modal stack component instead of the current navigation controller -> so the tabbar also gets popped away
            components: [
                new ComponentWithProperties(WaitingForVerificationView, {
                    customer: this.customer
                })
            ],
            animated: true,
            replace: 1,
            force: true,
            reverse: true
        })
    }

    async load() {
        this.loading = true

        try {
            const response = await Session.shared.authenticatedServer.request({
                method: "GET",
                path: "/customers/"+this.customer.id+"/promotions",
                decoder: new ArrayDecoder(PromotionCustomer as Decoder<PromotionCustomer>)
            })
            this.promotions = response.data

            if (this.delayOpeningId) {
                this.openById(this.delayOpeningId)
                this.delayOpeningId = null
            }
        } catch (e) {
            console.error(e)
        }
        
        this.loading = false
    }

    openPromotion(promotion: Promotion) {
        this.present(new ComponentWithProperties(NavigationController, {
            root: new ComponentWithProperties(PromotionView, { promotion, customer: this.customer })
        }))
    }
}
