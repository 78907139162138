
































import { BackButton,Checkbox,FloatingFooter, InputBox, List, ListItem,LoadingButton, NavigationBar } from "@restofrit/components"
import { CreateCustomer, CustomerCategory,CustomerCategoryHelper } from "@restofrit/structures";
import { ErrorBox, GeneralErrorsView } from "@simonbackx/simple-error-forms";
import { SimpleError } from "@simonbackx/simple-errors";
import { ComponentWithProperties, NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins, Prop } from "vue-property-decorator";

import ContactPersonView from "./ContactPersonView.vue";

@Component({
    components: {
        InputBox,
        GeneralErrorsView,
        LoadingButton,
        FloatingFooter,
        NavigationBar,
        List, 
        ListItem,
        Checkbox,
        BackButton
    },
})
export default class CategorySelectionView extends Mixins(NavigationMixin) {
    errorBox: ErrorBox | null = null
    loading = false

    @Prop({ required: true })
    customer: CreateCustomer

    @Prop({ required: true })
    callback: () => void

    get categories() {
        return Object.values(CustomerCategory)
    }

    getName(category: CustomerCategory) {
        return CustomerCategoryHelper.getName(category, this.$i18n as any)
    }

    get selectedCategories() {
        return this.customer.settings.categories
    }

    set selectedCategories(categories: CustomerCategory[]) {
        this.customer.settings.categories = categories
    }

    next() {
        if (this.selectedCategories.length == 0) {
            this.errorBox = new ErrorBox(new SimpleError({
                code: "categories_empty",
                message: "Categories empty",
                human: this.$t('errors.emptyCategories').toString()
            }))
            return
        }
        this.errorBox = null
        this.show(new ComponentWithProperties(ContactPersonView, { customer: this.customer, callback: this.callback }))
    }
    
}
