













import { FloatingFooter,InputBox, LoadingButton } from "@restofrit/components"
import { GeneralErrorsView } from "@simonbackx/simple-error-forms";
import { HistoryManager, NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins } from "vue-property-decorator";

@Component({
    components: {
        InputBox,
        GeneralErrorsView,
        LoadingButton,
        FloatingFooter
    },
})
export default class PrivacyView extends Mixins(NavigationMixin) {
    mounted() {
        HistoryManager.setUrl("/privacy");
    }
}
