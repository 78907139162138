































import { BackButton,FloatingFooter, InputBox, List, ListItem,LoadingButton, NavigationBar, NumberInput,PromotionBox } from "@restofrit/components"
import { Session } from "@restofrit/networking";
import { Customer, Order, OrderSettings, PromotionCustomer } from "@restofrit/structures";
import { Decoder } from "@simonbackx/simple-encoding";
import { ErrorBox, GeneralErrorsView } from "@simonbackx/simple-error-forms";
import { ComponentWithProperties, NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins, Prop } from "vue-property-decorator";

import ConfirmationView from "./ConfirmationView.vue";

@Component({
    components: {
        InputBox,
        GeneralErrorsView,
        NumberInput,
        LoadingButton,
        FloatingFooter,
        NavigationBar,
        List, 
        ListItem,
        PromotionBox,
        BackButton
    },
})
export default class ConfirmPromotionView extends Mixins(NavigationMixin) {
    loading = false

    @Prop({ required: true })
    promotion!: PromotionCustomer

    @Prop({ required: true })
    customer!: Customer

    errorBox: ErrorBox | null = null

    @Prop({ required: true })
    amount!: number

    
    async next() {
        if (this.loading) {
            return
        }
        this.loading = true

        try {
            const response = await Session.shared.authenticatedServer.request({
                method: "POST",
                path: "/customers/"+this.customer.id+"/order",
                body: OrderSettings.create({
                    promotion: this.promotion,
                    amount: this.amount
                }),
                decoder: Order as Decoder<Order>
            })

            this.promotion.orders.push(response.data)

            this.show({
                components: [
                    new ComponentWithProperties(ConfirmationView, {})
                ],
                animated: true,
                replace: this.navigationController!.components.length - 1,
                force: true
            })
        } catch (e) {
            console.error(e)
            this.errorBox = new ErrorBox(e)
        }
        
        this.loading = false
    }
    
}
