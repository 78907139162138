






import { App as CapApp } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { PushNotifications } from "@capacitor/push-notifications"
import { AuthenticatedView, PromiseView } from "@restofrit/containers";
import { load, switchLanguage } from '@restofrit/i18n';
import { Session } from "@restofrit/networking";
import { Customer, CustomerStatus, DeviceToken, DeviceTokenType } from "@restofrit/structures";
import { ArrayDecoder, Decoder } from "@simonbackx/simple-encoding";
import { ComponentWithProperties, HistoryManager, ModalStackComponent, NavigationController } from "@simonbackx/vue-app-navigation";
import { Component, Vue } from "vue-property-decorator";

import ChooseWholesalerView from "./views/create-customer/ChooseWholesalerView.vue";
import PromotionsView from "./views/promotions/PromotionsView.vue";
import TabBarController from './views/promotions/TabBarController.vue';
import { TabBarItem } from './views/promotions/TabBarItem';
import SettingsView from './views/settings/SettingsView.vue';
import WaitingForVerificationView from "./views/welcome/WaitingForVerificationView.vue";
import WelcomeView from "./views/welcome/WelcomeView.vue";

@Component({
    components: {
        ModalStackComponent
    },
})
export default class App extends Vue {
    root = this.getComponent()
    
    activeCustomer: Customer | null = null

    pendingRegistrationToken: string | null = null

    getComponent() {
        return new ComponentWithProperties(PromiseView, {
            promise: this.buildComponent.bind(this)
        })
    }

    async buildComponent() {
        await load()
        return new ComponentWithProperties(AuthenticatedView, {
            root: new ComponentWithProperties(PromiseView, {
                promise: async () => {
                    const response = await Session.shared.authenticatedServer.request({
                        method: "GET",
                        path: "/customers/customers",
                        decoder: new ArrayDecoder(Customer as Decoder<Customer>)
                    })
                    const customers = response.data
                    if (customers.length == 0) {
                        // Onboarding flow
                        return new ComponentWithProperties(NavigationController, { 
                            root: new ComponentWithProperties(ChooseWholesalerView, {
                                callback: () => {
                                    // Reload
                                    (this.$refs.modalStack as ModalStackComponent).replace(this.getComponent(), false);
                                }
                            })
                        })
                    }

                    const customer = customers[0]
                    this.activeCustomer = customer

                    switchLanguage(customer.settings.language).catch(console.error)

                    // We need to send notification token again if 
                    // we already registered in the same flow
                    this.sendNotificationTokenIfNeeded().catch(console.error)

                    // Add live reload to this customer
                    await CapApp.addListener('appStateChange', ({ isActive }) => {
                        if (this.activeCustomer !== customer) {
                            // Ignore because a new customer is already fetched
                            return
                        }
                        console.log('App state changed. Is active?', isActive);
                        if (isActive) {
                            // Refetch the customers
                            this.reloadCustomer(customer).catch(e => {
                                console.error(e)
                            })
                        }
                    });

                    // Ask permissions for push notifications
                    if (Capacitor.getPlatform() === 'ios' || Capacitor.getPlatform() === 'android') {
                        const permissions = await PushNotifications.checkPermissions()

                        if (permissions.receive !== "denied" && permissions.receive !== "granted" && customer.settings.notificationsEnabled) {
                            // Ask for permissions
                            await PushNotifications.requestPermissions()
                        }
                        PushNotifications.register().catch(console.error)
                    }

                    // Check status
                    if (customer.settings.status !== CustomerStatus.Accepted) {
                        return new ComponentWithProperties(ModalStackComponent, {
                            root: new ComponentWithProperties(NavigationController, { 
                                root: new ComponentWithProperties(WaitingForVerificationView, {
                                    customer
                                })
                            })
                        })
                    }

                    // Todo: pass customer along

                    return new ComponentWithProperties(ModalStackComponent, {
                        root: new ComponentWithProperties(TabBarController, {
                            items: [
                                new TabBarItem("promotions.title", "promotions", new ComponentWithProperties(NavigationController, { 
                                    root: new ComponentWithProperties(PromotionsView, {
                                        customer
                                    })
                                })),
                                new TabBarItem("settings.title", "settings", new ComponentWithProperties(NavigationController, { 
                                    root: new ComponentWithProperties(SettingsView, {
                                        customer
                                    })
                                }))
                            ]
                        })
                    })
                }
            }),
            loginRoot: new ComponentWithProperties(NavigationController, { 
                root: new ComponentWithProperties(WelcomeView, {})
            })
        });
    }

    async reloadCustomer(customer: Customer) {
        console.log("focused!")

        const response = await Session.shared.authenticatedServer.request({
            method: "GET",
            path: "/customers/customers",
            decoder: new ArrayDecoder(Customer as Decoder<Customer>)
        })

        const customers = response.data
        const c = customers.find(cc => cc.id === customer.id)
        if (c) {
            customer.set(c)
            console.log("updated customer")
        }
    }

    async sendNotificationTokenIfNeeded() {
        if (!this.pendingRegistrationToken) {
            return
        }
        const customer = this.activeCustomer
        if (!customer) {
            console.log("Received push notifications token, but without an active customer")
            return
        }
        // Sending the token to the server...
        await Session.shared.authenticatedServer.request({
            method: "POST",
            path:  "/customers/"+customer.id+"/register-device",
            body: DeviceToken.create({
                id: this.pendingRegistrationToken,
                type: Capacitor.getPlatform() === 'ios' ? DeviceTokenType.iOS : DeviceTokenType.Android
            })
        })
        this.pendingRegistrationToken = null
    }
    
    mounted() {
        HistoryManager.activate();

        if (Capacitor.getPlatform() === 'ios' || Capacitor.getPlatform() === 'android') {
            CapApp.addListener('appUrlOpen', (data) => {
                console.log("Did open app with url "+data.url)
                // Example url: https://beerswift.app/tabs/tabs2
                // slug = tabs/tabs2
                const url = new URL(data.url);
                const slug = url.pathname + url.search;
                console.log(slug)

                // We only push to the route if there is a slug present
                if (slug.length > 0) {
                    // Reload the view completely
                    HistoryManager.setUrl(slug);
                    (this.$refs.modalStack as ModalStackComponent).replace(this.getComponent(), false);
                }
            }).catch(console.error);

            PushNotifications.addListener("registration", (token) => {
                console.log("Received push notifications token, "+token.value)
                this.pendingRegistrationToken = token.value
                this.sendNotificationTokenIfNeeded().catch(console.error)
            }).catch(console.error)

            PushNotifications.addListener("registrationError", (err) => {
                console.error("Registration for push notifications failed with error")
                console.error(err)
            }).catch(console.error)

            CapApp.addListener('backButton', () => {
                // This fixes the Android behaviour for the back button that won't close the app
                // We don't use history.length since it appears to be bugged in capacitor
                if (HistoryManager.counter > 0) {
                    window.history.back()
                } else {
                    CapApp.exitApp()
                }
            }).catch(console.error);
        }
    }
}
