

































import { BackButton, FloatingFooter, InputBox, LoadingButton, NavigationBar } from "@restofrit/components"
import { Session } from "@restofrit/networking";
import { Language } from "@restofrit/structures";
import { CreateCustomer, Customer } from "@restofrit/structures";
import { ErrorBox, GeneralErrorsView } from "@simonbackx/simple-error-forms";
import { SimpleError, SimpleErrors } from "@simonbackx/simple-errors";
import { ComponentWithProperties, NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins, Prop } from "vue-property-decorator";
import { isValidPhoneNumber } from 'libphonenumber-js'

import WaitingForVerificationView from "../welcome/WaitingForVerificationView.vue";

@Component({
    components: {
        InputBox,
        GeneralErrorsView,
        LoadingButton,
        FloatingFooter,
        NavigationBar,
        BackButton
    },
})
export default class ContactPersonView extends Mixins(NavigationMixin) {
    @Prop({ required: true })
    customer: CreateCustomer

    @Prop({ required: true })
    callback: () => void

    errorBox: ErrorBox | null = null
    loading = false

    get contactName() {
        return this.customer.settings.contactName
    }

    set contactName(contactName: string) {
        this.customer.settings.contactName = contactName
    }

    get contactPhone() {
        return this.customer.settings.contactPhone
    }

    set contactPhone(contactPhone: string) {
        this.customer.settings.contactPhone = contactPhone
    }

    async next() {
        if (this.loading) {
            return
        }

        this.customer.settings.language = this.$i18n.locale.substr(0, 2) as Language

        // Create customer
        try {
            this.loading = true

            this.errorBox = null

            this.contactName = this.contactName.trim()
            this.contactPhone = this.contactPhone.trim()

            const errors = new SimpleErrors()
            if (this.contactName.length == 0) {
                errors.addError(new SimpleError({
                    code: "invalid_field",
                    message: "contactName is too short",
                    human: this.$t('errors.emptyContactName').toString(),
                    field: "contactName"
                }))
            }

            if (this.contactPhone.length == 0 || !isValidPhoneNumber(this.contactPhone, "BE")) {
                errors.addError(new SimpleError({
                    code: "invalid_field",
                    message: "contactPhone is invalid",
                    human: this.$t('errors.invalidPhone').toString(),
                    field: "contactPhone"
                }))
            }

            errors.throwIfNotEmpty()

            await Session.shared.authenticatedServer.request({
                method: "POST",
                path: "/customers/create-customer",
                body: this.customer.getId(),
                decoder: Customer
            })

            // Rebuild component (needed so we request push notification permissions etc) + set active customer
            this.callback()
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }

        this.loading = false
        
    }
    
}
