
































import { Capacitor } from "@capacitor/core";
import { PushNotifications } from "@capacitor/push-notifications";
import { BackButton, Checkbox, List, ListItem, NavigationBar } from "@restofrit/components";
import { Session } from "@restofrit/networking";
import { Customer, CustomerSettings } from "@restofrit/structures";
import { Decoder } from "@simonbackx/simple-encoding";
import { Request } from "@simonbackx/simple-networking";
import { NavigationMixin } from "@simonbackx/vue-app-navigation";
import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings';
import { Component, Mixins, Prop } from "vue-property-decorator";

@Component({
    components: {
        NavigationBar,
        List, 
        ListItem,
        Checkbox,
        BackButton
    },
})
export default class NotificationSettingsView extends Mixins(NavigationMixin) {
    @Prop({ required: true })
    customer!: Customer

    didDeny = false
    cachedEnableNotifications = false

    mounted() {
        this.cachedEnableNotifications = this.customer.settings.notificationsEnabled
        this.handleVisibilityChange()
        document.addEventListener("visibilitychange", this.handleVisibilityChange, { passive: true });
    }
    
    handleVisibilityChange() {
        if (this.cachedEnableNotifications) {
            PushNotifications.requestPermissions().then(permissions => {
                this.didDeny = permissions.receive === 'denied'

                // Double check the token
                PushNotifications.register().catch(console.error)
            }).catch(console.error);
        } else {
            PushNotifications.checkPermissions().then(permissions => {
                this.didDeny = permissions.receive === 'denied'
            }).catch(console.error);
        }
    }

    beforeDestroy() {
        document.removeEventListener("visibilitychange", this.handleVisibilityChange);
    }

    get enableNotifications() {
        return this.cachedEnableNotifications
    }

    set enableNotifications(enabled: boolean) {
        if (this.didDeny) {
            return
        }
        this.cachedEnableNotifications = enabled

        Request.cancelAll(this)
        Session.shared.authenticatedServer.request({
            method: "PATCH",
            path: "/customers/"+this.customer.id,
            body: CustomerSettings.patch({
                notificationsEnabled: enabled
            }),
            decoder: Customer as Decoder<Customer>,
            owner: this
        }).then(request => {
            const customer = request.data
            this.customer.set(customer)
        }).catch(() => {
            console.error("Failed to save notification settings")
        })
    }

    openSettings() {
        if (Capacitor.getPlatform() === 'ios') {
            NativeSettings.openIOS({
                option: IOSSettings.App,
            });
        } else {
            NativeSettings.openAndroid({
                option: AndroidSettings.ApplicationDetails,
            });
        }
    }
}
